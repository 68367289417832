import { Route } from "@dolthub/web-utils";
import * as ps from "./params";

const ENCODE = true;

export const org = (p: ps.OrgParams & { tab?: string }): Route => {
  const o = new Route("/organizations");
  return o.addDynamic("orgName", p.orgName).withQuery({ tab: p.tab });
};

export const orgSamlLogin = (p: ps.OrgParams): Route => {
  const o = org(p);
  return o.addStatic("sso");
};

export const orgTeam = (p: ps.TeamParams): Route =>
  org(p).withQuery({ tab: "teams", teamName: p.teamName });

export const deployment = (
  p: ps.DeploymentParams & { tab?: string; id?: string },
): Route => {
  const d = new Route("/deployments");
  return d
    .addDynamic("ownerName", p.ownerName)
    .addDynamic("deploymentName", p.deploymentName)
    .withQuery({ tab: p.tab })
    .withHash(p.id);
};

export const createDeployment = (
  p: Partial<ps.CreateDeploymentParams>,
): Route => {
  const cd = new Route("/create-deployment");

  return cd.withQuery({
    instanceId: p.instanceId,
    zone: p.zone,
    ownerName: p.ownerName,
    deploymentName: p.deploymentName,
    backupName: p.backupName,
    webPKI: p.webPKI ? "true" : undefined,
  });
};

export const pricing = (p: Partial<ps.PricingParams>): Route => {
  const cd = new Route("pricing");

  return cd.withQuery({
    instanceId: p.instanceId,
    zone: p.zone,
  });
};

// Database

export const database = (p: ps.DatabaseParams): Route =>
  deployment(p)
    .addStatic("database")
    .addDynamic("databaseName", p.databaseName);

export const ref = (p: ps.RefParams): Route =>
  database(p).addStatic("data").addDynamic("refName", p.refName, ENCODE);

export const commitLog = (p: ps.RefParams & { commitId?: string }): Route =>
  database(p)
    .addStatic("commits")
    .addDynamic("refName", p.refName, ENCODE)
    .withHash(p.commitId);

export const commitGraph = (p: ps.RefParams): Route =>
  commitLog(p).addStatic("graph");

export const defaultDocDefaultBranch = (p: ps.DatabaseParams): Route =>
  database(p).addStatic("doc");

export const defaultDoc = (p: ps.RefParams): Route =>
  defaultDocDefaultBranch(p).addDynamic("refName", p.refName, ENCODE);

export const doc = (p: ps.DocParams): Route =>
  defaultDoc(p).addDynamic("docName", p.docName, ENCODE);

export const newDoc = (p: ps.RefParams): Route =>
  defaultDoc(p).addStatic("new");

export const pulls = (p: ps.DatabaseParamsOptionalRef): Route =>
  database(p).addStatic("pulls").withQuery({ refName: p.refName });

export const newPull = (
  p: ps.DatabaseParamsOptionalRef & { fromBranchName?: string },
): Route =>
  pulls(p)
    .addStatic("new")
    .withQuery({ refName: p.refName, fromBranchName: p.fromBranchName });

export const pullsWithQuery = (
  p: ps.DatabaseParamsOptionalRef & {
    q?: string;
    filter?: string;
  },
): Route =>
  pulls(p).withQuery({ query: p.q, filter: p.filter, refName: p.refName });

export const pull = (p: ps.PullParamsOptionalRef): Route =>
  pulls(p).addDynamic("pullId", p.pullId).withQuery({ refName: p.refName });

export const pullDiff = (p: ps.PullParamsOptionalRef): Route =>
  pull(p).addStatic("compare").withQuery({ refName: p.refName });

export const pullDiffRange = (p: ps.PullDiffParamsOptionalRef): Route => {
  const diffRange = `${p.fromCommitId ?? ""}..${p.toCommitId ?? ""}`;
  return pullDiff(p)
    .addDynamic("diffRange", diffRange)
    .withQuery({ refName: p.refName });
};

export const query = (p: ps.RefParams): Route =>
  database(p).addStatic("query").addDynamic("refName", p.refName, ENCODE);

export const sqlQuery = (p: ps.SqlQueryParams): Route =>
  query(p).withQuery({ q: p.q, active: p.active });

export const branches = (p: ps.DatabaseParamsMaybeRef): Route =>
  database(p).addStatic("branches").withQuery({ refName: p.refName });

export const newBranch = (p: ps.DatabaseParamsOptionalRef): Route =>
  branches(p).addStatic("new").withQuery({ refName: p.refName });

export const table = (p: ps.TableParams): Route =>
  ref(p).addDynamic("tableName", p.tableName);

export const editTable = (p: ps.TableParams): Route =>
  table(p).withQuery({ edit: "true" });

export const commit = (p: ps.CommitParams): Route =>
  database(p)
    .addStatic("compare")
    .addDynamic("refName", p.refName, ENCODE)
    .addDynamic("diffRange", p.commitId);

export const diff = (p: ps.DiffParams): Route =>
  commit({ ...p, commitId: getDiffRange(p) });

function getDiffRange(p: ps.DiffParams): string {
  if (!p.fromCommitId && !p.toCommitId) return "";
  if (!p.toCommitId) return p.fromCommitId ?? "";
  return `${p.fromCommitId ?? ""}..${p.toCommitId}`;
}

export const releases = (p: ps.DatabaseParamsOptionalRef): Route =>
  database(p).addStatic("releases").withQuery({ refName: p.refName });

export const newRelease = (p: ps.DatabaseParamsOptionalRef): Route =>
  releases(p).addStatic("new").withQuery({ refName: p.refName });

export const schemaDiagram = (p: ps.RefParams & { active?: string }): Route =>
  database(p)
    .addStatic("schema")
    .addDynamic("refName", p.refName, ENCODE)
    .withQuery({ active: p.active });

export const createTable = (p: ps.DatabaseParamsOptionalRef): Route =>
  database(p)
    .addStatic("data")
    .addStatic("create")
    .withQuery({ refName: p.refName });

// File upload
export const upload = (p: ps.DatabaseParams): Route =>
  database(p).addStatic("upload");

export const uploadStage = (
  p: ps.UploadParams & {
    refName?: string;
    tableName?: string;
    spreadsheet?: boolean;
    stage: string;
  },
): Route => {
  const q = p.refName
    ? {
        branchName: p.refName,
        tableName: p.tableName,
        spreadsheet: p.spreadsheet ? "true" : undefined,
      }
    : {};
  return upload(p)
    .addDynamic("uploadId", p.uploadId)
    .addDynamic("stage", p.stage)
    .withQuery(q);
};

// Sign In link with redirect

export const signinWithRedirect = (p: {
  asPath: string;
  open?: string;
}): Route => {
  const s = new Route("/signin");
  return s.withQuery({ redirect: p.asPath, open: p.open });
};

export const support = (
  p?: { tab?: string } & Partial<ps.DeploymentParams>,
): Route => {
  const s = new Route("/support");
  if (!p) return s;
  return s.withQuery(p);
};

export const incident = (p: ps.IncidentParams): Route =>
  support().addDynamic("incidentName", p.incidentName);

export const settings = (p?: { section: string }): Route => {
  const s = new Route("/settings");
  if (!p) return s;
  return s.withHash(p.section);
};
